import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LaborOrder } from 'src/app/core/interfaces/labor-order.interface';
import { NotificationService } from 'src/app/core/services/notification.service';
import { OrdersService } from 'src/app/features/orders/services/orders.service';
import { DialogFooterComponent } from 'src/app/shared/components/dialog-footer/dialog-footer.component';
import { TextInputComponent } from 'src/app/shared/components/text-input/text-input.component';
import { MomentFormatPipeStandalone } from 'src/app/shared/pipes/moment-format.pipe-standalone';
import { AssociateTimesheetToOrder } from '../../states/timesheets/timesheets.actions';

@Component({
  selector: 'app-modal-select-labor-order',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MomentFormatPipeStandalone,
    TextInputComponent,
    DialogFooterComponent
  ],
  templateUrl: './modal-select-labor-order.component.html',
  styles: [`
    .base-input-label {
      margin-right: 0!important;
    }`
  ]
})
export class ModalSelectLaborOrderComponent implements OnInit, OnDestroy {

  searchOrderControl: UntypedFormControl;
  isSaving: boolean;
  isLoading: boolean;
  selectedLaborOrder: LaborOrder;
  laborOrders: Array<LaborOrder>;
  private unsubscribe$: Subject<void>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      timesheetId: string,
      laborOrder: LaborOrder,
      fromList: boolean
    },
    private store: Store,
    private dialogRef: MatDialogRef<ModalSelectLaborOrderComponent>,
    private _ordersService: OrdersService,
    private _notificationService: NotificationService
  ) {
    this.unsubscribe$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.searchOrderControl = new UntypedFormControl('');
    this.subscribeSearchControl();
  }

  private subscribeSearchControl(): void {
    this.searchOrderControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
    ).subscribe(
      (value) => {
        this.isLoading = true;
        if (value && value.length >= 3) {
          this.searchLaborOrders(value);
        } else {
          this.laborOrders = null;
          this.isLoading = false;
        }
      }
    );
  }

  private searchLaborOrders(value: string): void {
    this._ordersService.getOrders(0, 150, { searchQ: value }).toPromise().then(
      (response) => {
        this.laborOrders = response.items;
        this.isLoading = false;
      }
    )
  }

  associate(): void {
    this.isSaving = true;
		this.dialogRef.disableClose = true;
		this.store.dispatch(new AssociateTimesheetToOrder(
      this.data.timesheetId,
      this.selectedLaborOrder.id,
      this.data.fromList,
      (response) => {
        this._notificationService.showSuccess("Timesheet was associate succesfully.");
        this.close();
      },
      (error) => {
        if (error?.status === 403) {
          this._notificationService.showError(error?.error?.error?.message);
        } else {
          this._notificationService.showError("There was a problem associating timesheet to labor order. Please try again.");
        }
        this.isSaving = false;
      }
    ));
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
